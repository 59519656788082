<template>
  <AssetMainStyled>
    <ExtensionTableMolecule
      :columns="columns"
      :tableData="tableData"
      :sortOption="sortOption"
      :checkboxOption="checkboxOption"
      :title="'Assets'"
      :buttonText="'Save Assets'"
      :maxHeight="getHeight"
      :isLoading="isLoading"
      :sort-option="sortOption"
      :showBackButton="true"
      :goBackRoute="goBackRoute"
      :enableSearch="true"
      :searchQuery="searchQuery"
      :disabledAction="!selectedAssetIds.length"
      @search="textSearch($event)"
      @action="updateExtensionWithAssets($event)"
    />
  </AssetMainStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { concat, orderBy, partition, get } from 'lodash'

import localesMixin from '@/mixins/locales'
import { FlashMessages } from '@common/singletons'
import ExtensionTableMolecule from './ExtensionTableMolecule.vue'

import MY_EXTENSION_QUERY from '#/graphql/extensionBuilder/myExtension.gql'
import ASSETS_MINIMALS_QUERY from '#/graphql/operations/assets//minimals/assetsMinimal.gql'
import OEMS_QUERY from '#/graphql/operations/assets/minimals/oems.gql'
import UPDATE_EXTENSION_MUTATION from '#/graphql/extensionBuilder/updateExtension.gql'

const AssetMainStyled = styled('div')`
  display: grid;
  padding: 0.5rem;
`

export default {
  inject: ['uiSettings'],
  mixins: [localesMixin],
  components: {
    AssetMainStyled,
    ExtensionTableMolecule,
  },
  data() {
    return {
      searchQuery: '',
      assets: [],
      selectedAssetIds: [],
      myExtensions: [],
      sortOption: {
        multipleSort: false,
        sortChange: params => {
          this.sortChange(params)
        },
      },
      oemsMap: new Map(),
    }
  },
  computed: {
    myExtension() {
      return this.myExtensions[0]
    },
    checkboxOption() {
      return {
        selectedRowChange: ({ selectedRowKeys }) => {
          this.selectedAssetIds = selectedRowKeys
        },
        selectedAllChange: ({ selectedRowKeys }) => {
          this.selectedAssetIds = selectedRowKeys
        },
        defaultSelectedRowKeys: this.myExtension?.assetIds,
      }
    },
    extensionId() {
      return this.$route.params.id
    },
    isLoading() {
      return this.$apollo.loading
    },
    goBackRoute() {
      return {
        name: 'CreateExtension',
        id: this.extensionId,
      }
    },
    mappedAssets() {
      return this.assets.map(asset => {
        const oem = this.oemsMap.get(asset.manufacturerCuid)
        return {
          ...asset,
          oem,
        }
      })
    },
    columns() {
      const header = [
        {
          field: 'name',
          key: 'name',
          title: this.$tc('name', 1),
          align: 'left',
          type: 'checkbox',
          sortBy: 'asc',
          width: 500,
        },
        { field: 'type', key: 'type', title: 'Type', align: 'left', sortBy: 'asc', width: 200 },
        { field: 'oem', key: 'oem', title: 'OEM', align: 'left', sortBy: 'asc', width: 100 },
        { field: 'model', key: 'model', title: 'Model', align: 'left', width: 200, sortBy: 'asc' },
      ]
      return header
    },
    tableData() {
      const tableData = this.mappedAssets?.map(asset => {
        return {
          rowKey: asset.id,
          name: asset?.name,
          type: asset?.type?.name,
          oem: asset?.oem?.alias,
          model: asset?.model?.name,
        }
      })

      const filteredTable = tableData.filter(item =>
        Object.values(item).some(value => String(value)?.toLowerCase().includes(this.searchQuery?.toLowerCase())),
      )
      return filteredTable
    },
    messages() {
      let message = ''
      if (this.rows.length === 0) {
        message = this.searchQuery === '' ? this.$tc('messages.noData') : this.$t('messages.searchNoResults', { query: this.searchQuery })
      }
      return message
    },
    getHeight() {
      return window.innerHeight - 150
    },
  },
  methods: {
    textSearch(query) {
      this.searchQuery = query
    },
    sortChange(params) {
      const sortKey = Object.keys(params).filter(key => params[key] !== '')
      const sortDirection = params[sortKey]
      const partitions = partition(this.mappedAssets, x => !!get(x, sortKey, null))
      this.mappedAssets = concat(orderBy(partitions[0], [sortKey], [sortDirection]), partitions[1])
    },
    async updateExtensionWithAssets() {
      try {
        const extension = await this.$apollo.mutate({
          mutation: UPDATE_EXTENSION_MUTATION,
          variables: {
            data: {
              name: this.myExtension?.name,
              description: this.myExtension?.description,
              assetIds: this.selectedAssetIds,
            },
            extensionId: this.$route.params.id,
          },
        })
        const extensionId = extension?.data?.updateExtension?.id
        if (extension?.data?.updateExtension?.id) {
          FlashMessages.$emit('success', `${this.selectedAssetIds.length} Asset(s) have been successfully Saved!`, {
            timeout: 3000,
          })
          this.$router.push({
            name: 'CreateExtension',
            params: {
              id: extensionId,
            },
          })
        }
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
  },
  apollo: {
    myExtensions: {
      query: MY_EXTENSION_QUERY,
      variables() {
        return {
          where: {
            id: {
              equals: this.extensionId,
            },
          },
        }
      },
      skip() {
        return !this.extensionId
      },
    },
    assets: {
      query: ASSETS_MINIMALS_QUERY,
      variables: {
        where: {
          isVisible: {
            in: [true],
          },
        },
      },
    },
    oems: {
      query: OEMS_QUERY,
      result({ data }) {
        this.oemsMap = new Map(data.oems.map(oem => [oem.id, oem]))
      },
    },
  },
}
</script>
