var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetMainStyled', [_c('ExtensionTableMolecule', {
    attrs: {
      "columns": _vm.columns,
      "tableData": _vm.tableData,
      "sortOption": _vm.sortOption,
      "checkboxOption": _vm.checkboxOption,
      "title": 'Assets',
      "buttonText": 'Save Assets',
      "maxHeight": _vm.getHeight,
      "isLoading": _vm.isLoading,
      "sort-option": _vm.sortOption,
      "showBackButton": true,
      "goBackRoute": _vm.goBackRoute,
      "enableSearch": true,
      "searchQuery": _vm.searchQuery,
      "disabledAction": !_vm.selectedAssetIds.length
    },
    on: {
      "search": function search($event) {
        return _vm.textSearch($event);
      },
      "action": function action($event) {
        return _vm.updateExtensionWithAssets($event);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }